/*eslint-disable*/
import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReplyIcon from '@mui/icons-material/Reply';
import ReportIcon from '@mui/icons-material/Report';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import avatarPlaceholderIcon from '../../images/icons/avatar.svg';
import useImage from '../../utils/hooks/useImage';
import { DateTime } from 'luxon';
import './ChatMessage.css';

import thankyouIcon from '../../images/icons/thankyouIcon.png';
export default function ChatMessage({ comment, index, onThankYou, id }) {
  const [commentData, setCommentData] = useState({});
  const userAvatar = useImage(commentData.user?.avatar, avatarPlaceholderIcon);

  useEffect(() => {
    setCommentData(formatComment(comment));
  }, [comment]);

  const handleOnThankYou = () => {
    const thankYouData = {
      id: commentData.id,
      toOwner: commentData.owner,
    };

    onThankYou(thankYouData);
  };

  function formatComment({ text, rating, date, owner, user }) {
    const formattedDate = DateTime.fromISO(date, { zone: 'utc' }).toFormat(
      'd/M/yy HH:mm'
    );
    return {
      index,
      text,
      rating,
      date: formattedDate,
      owner,
      user,
    };
  }

  return (
    <div id={commentData.index} className="chat-meassge-main">
      <div className="comment-box">
        <h3 className="comment-box__username">{commentData.user?.name}</h3>
        <span className="comment-box__date">{commentData.date}</span>
        <img src={userAvatar} className="comment-box__avatar"></img>
        <p className="comment-box__text">{commentData.text}</p>
        <div className="comment-box__buttons">
          <IconButton aria-label="Reply" title="Reply">
            <ReplyIcon />
          </IconButton>
          <IconButton aria-label="Report" title="Report">
            <ReportIcon />
          </IconButton>
          <IconButton aria-label="Edit" title="Edit">
            <EditIcon />
          </IconButton>
          <IconButton aria-label="Delete" title="Delete">
            <DeleteIcon />
          </IconButton>
          <IconButton
            onClick={handleOnThankYou}
            aria-label="send thank You"
            title="send thank You"
          >
            <img
              className="iconthankYou"
              src={thankyouIcon}
              alt="thankyouIcon"
            />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
