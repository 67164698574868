const RegexPatterns = {
  // eslint-disable-next-line no-useless-escape
  EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
};
const SCREEN_WIDTHS = {
  MOBILE_MENU_WIDTH: 590,
  TABLET_SIZE_WIDTH: 769,
};
const ENGLISH_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const CARDS_PAR_PAGE = 3;

export {
  RegexPatterns,
  SCREEN_WIDTHS,
  ENGLISH_MONTHS,
  CARDS_PAR_PAGE,
};
